import { ref } from 'vue'
export const useContentModal = () => {
  const contentId = ref<string | undefined>('')
  const isContentModalOpened = ref(false)

  const openContentModal = async (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const pathname =
      (event.target as HTMLAnchorElement).pathname ||
      ((event.target as Element).parentElement as HTMLAnchorElement).pathname ||
      ''

    contentId.value = pathname.substring(1).replace(/\//gi, '__')
    isContentModalOpened.value = true
  }

  const closeContentModal = () => {
    contentId.value = undefined
    isContentModalOpened.value = !isContentModalOpened.value
  }

  return { contentId, isContentModalOpened, openContentModal, closeContentModal }
}
