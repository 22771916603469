<template>
  <div class="asf-video" :style="styles">
    <iframe
      v-if="videoSource"
      :src="videoSource"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      title="videoTitle"
      @load="handleVideoLoad"
    />
    <AsfVideoWrapper
      v-show="!isLoadedOnce"
      :custom-thumbnail="videoThumbnail"
      :is-clicked="isClicked"
      :is-info-fetched="isInfoFetched"
      :is-loaded-once="isLoadedOnce"
      :is-video-found="isVideoFound"
      :provider="provider"
      :show-title="showTitle"
      :thumbnail-quality="thumbnailQuality"
      :video-id="videoId"
      :video-title="videoTitle"
      @click="handleClick"
    >
      <template #button>
        <slot name="video-button" />
      </template>
      <template #loader>
        <slot name="video-loader" />
      </template>
    </AsfVideoWrapper>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { AsfVideoProps, AsfVideoProvider } from '@ui/types'
import { fetchingVideo, getDailyMotionID, getVideoProvider, getVimeoID, getYouTubeID } from './Video.utils'

export default defineComponent({
  name: 'AsfVideo',
  props: {
    aspectRatio: { type: String as PropType<AsfVideoProps['aspectRatio']>, default: '16:9' },
    autoplay: { type: Boolean as PropType<AsfVideoProps['autoplay']>, default: false },
    customThumbnail: { type: String as PropType<AsfVideoProps['customThumbnail']>, default: '' },
    customTitle: { type: String as PropType<AsfVideoProps['customTitle']>, default: '' },
    maxWidth: { type: String as PropType<AsfVideoProps['maxWidth']>, default: '' },
    src: { type: String as PropType<AsfVideoProps['src']>, required: true },
    showTitle: { type: Boolean as PropType<AsfVideoProps['showTitle']>, default: true },
    thumbnailQuality: { type: String as PropType<AsfVideoProps['thumbnailQuality']>, default: 'standard' }
  },
  setup(props: AsfVideoProps) {
    const isClicked = ref(false)
    const isInfoFetched = ref(true)
    const isLoadedOnce = ref(false)
    const isVideoFound = ref(false)
    const videoInfo = ref<Record<string, unknown> | null>(null)
    const videoSource = ref('')

    const provider = computed(() => getVideoProvider(props.src))
    const aspectRatioValue = computed(() => {
      const defaultAspect = 56.25
      if (!props.aspectRatio) {
        return defaultAspect
      }

      const [width, height] = props.aspectRatio.split(':')

      if (!height) {
        return defaultAspect
      }

      return Number(((Number(height) / Number(width)) * 100).toFixed(2))
    })
    const styles = computed(() => ({
      '--video-aspect-ratio': `${aspectRatioValue.value}%`,
      '--video-max-width': props.maxWidth || '100%'
    }))
    const videoId = computed<string>(() => {
      if (provider.value === 'youtube') {
        return getYouTubeID(props.src)
      }

      if (provider.value === 'vimeo') {
        return getVimeoID(props.src)
      }

      if (provider.value === 'dailymotion') {
        return getDailyMotionID(props.src)
      }

      return ''
    })
    const videoTitle = computed(() => {
      if (props.customTitle) {
        return props.customTitle
      } else if (videoInfo.value !== null) {
        return videoInfo.value.title as string
      }

      return ''
    })
    const videoThumbnail = computed(() => {
      if (props.customThumbnail) {
        return props.customThumbnail
      } else if (videoInfo.value !== null && (provider.value === 'vimeo' || provider.value === 'dailymotion')) {
        return videoInfo.value.thumbnail_url as string
      }

      return undefined
    })

    const initIframe = (src: string, platform?: AsfVideoProvider) => {
      if (!videoId.value) {
        return
      }

      const url = new URL(src)

      const params =
        url.search &&
        url.search
          .substring(1)
          .split('&')
          .filter((p) => !p.includes(videoId.value as string))

      if (platform === 'vimeo') {
        videoSource.value = `https://player.vimeo.com/video/${videoId.value}?autoplay=1${
          params ? `&${params.join('&')}` : ''
        }`
      } else if (platform === 'youtube') {
        videoSource.value = `https://www.youtube.com/embed/${videoId.value}?autoplay=1${
          params ? `&${params.join('&')}` : ''
        }`
      } else if (platform === 'dailymotion') {
        videoSource.value = `https://www.dailymotion.com/embed/video/${videoId.value}?autoplay=1${
          params ? `&${params.join('&')}` : ''
        }`
      }
    }

    const playVideo = () => {
      if (!isInfoFetched.value && !isLoadedOnce.value && isVideoFound.value) {
        initIframe(props.src, provider.value)
      }
    }

    const handleClick = () => {
      isClicked.value = true
      playVideo()
    }

    const handleVideoLoad = () => {
      if (!isInfoFetched.value && !isLoadedOnce.value) {
        isLoadedOnce.value = true
      }
    }

    const init = () =>
      fetchingVideo(props.src, provider.value)
        .then((response) => {
          videoInfo.value = response
          isVideoFound.value = true
        })
        .catch(() => {
          videoInfo.value = null
          isVideoFound.value = false
        })
        .finally(() => {
          isInfoFetched.value = false

          if (props.autoplay) {
            playVideo()
          }
        })

    onMounted(() => init())

    return {
      isClicked,
      isInfoFetched,
      isLoadedOnce,
      isVideoFound,
      styles,
      provider,
      videoId,
      videoInfo,
      videoSource,
      videoThumbnail,
      videoTitle,
      handleClick,
      handleVideoLoad
    }
  }
})
</script>
<style lang="postcss">
@import '@components/atoms/Video/Video.css';
</style>
