<template>
  <AsfList tag="ol" class="asf-slider__bullets" :class="modifier">
    <AsfListItem v-if="showPlayButton">
      <slot name="autoplay-button" />
    </AsfListItem>
    <template v-for="(i, idx) in inactiveLeft" :key="idx">
      <AsfListItem :data-index="i">
        <AsfButton :aria-label="`${$t('slider.goToSlide', [idx + 1])}`" class="asf-slider__bullet" @click="go(idx)">
          {{ idx }}
        </AsfButton>
      </AsfListItem>
    </template>
    <AsfListItem>
      <AsfButton :aria-label="`${$t('slider.current')}`" class="asf-slider__bullet is-active" />
    </AsfListItem>
    <template v-for="(i, idx) in inactiveRight" :key="inactiveLeft + 1 + idx">
      <AsfListItem :data-index="i">
        <AsfButton
          :aria-label="`${$t('slider.goToSlide', [inactiveLeft + 2 + idx])}`"
          class="asf-slider__bullet"
          @click="go(inactiveLeft + 1 + idx)"
        />
      </AsfListItem>
    </template>
  </AsfList>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfSliderBulletsProps } from '@ui/types'

export default defineComponent({
  name: 'AsfSliderBullets',
  props: {
    current: { type: Number as PropType<AsfSliderBulletsProps['current']>, default: 0 },
    total: { type: Number as PropType<AsfSliderBulletsProps['total']>, default: 0 },
    options: { type: Object as PropType<AsfSliderBulletsProps['options']>, default: null }
  },
  setup(props: AsfSliderBulletsProps, { emit }) {
    const inactiveRight = computed(() => {
      if (props.total) {
        return props.total - 1 - props.current
      }

      return 0
    })
    const inactiveLeft = computed(() => {
      if (props.total) {
        return props.total - inactiveRight.value - 1
      }

      return 0
    })
    const modifier = computed(() => {
      if (!props.options) {
        return ''
      }

      return {
        'm-absolute': props.options.position === 'absolute'
      }
    })
    const showPlayButton = computed(() => props.options?.showPlayButton)
    const go = (index: number) => emit('click:bullet', index)

    return { inactiveRight, inactiveLeft, modifier, showPlayButton, go }
  }
})
</script>
