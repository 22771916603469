<template>
  <div class="asf-content-nav-grid">
    <AsfList class="asf-content-nav-grid__list">
      <AsfListItem v-for="(item, index) in items" :key="`grid-item-${index}`" class="asf-content-nav-grid__item">
        <ContentLink
          v-bind="item"
          class="asf-content-nav-grid__link"
          :style="{ color: item.color }"
          :class="{
            'justify-center': item.alignment === 'center',
            'justify-start': item.alignment === 'left',
            'justify-end': item.alignment === 'right'
          }"
        />
      </AsfListItem>
    </AsfList>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, PropType } from 'vue'
import { ContentNavigationGridProps } from '../types'

export default defineComponent({
  name: 'ContentNavigationGrid',
  components: {
    ContentLink: defineAsyncComponent(() => import('~/components/ContentLink.vue'))
  },
  props: {
    items: { type: Array as PropType<ContentNavigationGridProps['items']>, required: true }
  }
})
</script>
<style src="~/styles/ContentNavigationGrid.css"></style>
