<template>
  <AsfHeading :tag="tag" :appearance="appearance" :styles="styles">
    <span>{{ text }}</span>
    <template v-if="$slots.default" #content-subtitle>
      <slot />
    </template>
  </AsfHeading>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ContentHeadingProps } from '../types'

export default defineComponent({
  name: 'ContentHeading',
  props: {
    text: { type: String as PropType<ContentHeadingProps['text']>, default: '' },
    color: { type: String as PropType<ContentHeadingProps['color']>, default: '' },
    alignment: { type: String as PropType<ContentHeadingProps['alignment']>, default: '' },
    tag: { type: String as PropType<ContentHeadingProps['tag']>, default: 'h1' },
    appearance: { type: String as PropType<ContentHeadingProps['appearance']>, default: '' }
  },
  setup(props: ContentHeadingProps) {
    const styles = computed(() => ({
      // an undefined value must be defined explicitly to avoid the case when the value is an empty string.
      // this is possible due to the way how Amplience is configured
      color: props.color || undefined,
      textAlign: props.alignment || undefined
    }))

    return { styles }
  }
})
</script>
