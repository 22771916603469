<template>
  <div class="asf-slider__slide" v-bind="$attrs">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AsfSliderItem'
})
</script>
