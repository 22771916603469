<script lang="ts" setup>
import { ContentAccordionProps } from '../types'
const props = defineProps<ContentAccordionProps>()

type initialOpenConfig = ContentAccordionProps['config'] & {
  initialOpen: string
}

const initialOpen = computed(() => {
  const contentConfig = props.config as initialOpenConfig

  if (contentConfig?.initialOpen === 'expanded') {
    return 'all'
  } else if (contentConfig?.initialOpen === 'first') {
    return ['accordion-0']
  }

  return ''
})
</script>
<template>
  <div>
    <ContentHeading v-bind="title" />
    <AsfAccordion :initial-open="initialOpen">
      <template v-for="({ title, content }, idx) in items" :key="`accordion-${idx}`">
        <AsfAccordionItem v-if="content.content" :id="`accordion-${idx}`" :label="title">
          <component :is="content.componentName" v-bind="content" />
        </AsfAccordionItem>
      </template>
    </AsfAccordion>
  </div>
</template>
