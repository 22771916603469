<script setup lang="ts">
import { ComponentAgreementProps } from '../../types'

defineProps<ComponentAgreementProps>()
const { contentId, isContentModalOpened, openContentModal, closeContentModal } = useContentModal()
</script>
<template>
  <div class="asf-checkbox__label-content">
    <span>{{ $t('forms.profile.agreeToPrivacy') }}&nbsp;</span>
    <AsfLink :link="termsConditions?.url || '#'" class="asf-link-primary" is-anchor @click="openContentModal">
      {{ termsConditions?.label || $t('loginPage.termsConditions') }}
    </AsfLink>
    <span>&nbsp;{{ '&' }}&nbsp;</span>
    <AsfLink :link="privacyPolicy?.url || '#'" class="asf-link-primary" is-anchor @click="openContentModal">
      {{ privacyPolicy?.label || $t('loginPage.privacyPolicy') }}
    </AsfLink>
    <Teleport to="#agreement-modal-placeholder">
      <ContentModal v-if="contentId" :id="contentId" :visible="isContentModalOpened" @close:modal="closeContentModal" />
    </Teleport>
  </div>
</template>
