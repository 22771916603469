<template>
  <div class="asf-accordion" :class="{ 'is-large': large }" ref="accordion">
    <slot />
  </div>
</template>
<script lang="ts">
import { FocusableElement } from 'shared/directives'
import { AsfAccordionProps, AsfKeyValue } from '@ui/types'
import { HandleKeydownKey, SelectedItemsKey, ToggleItemKey } from './Accordion.utils'

export default defineComponent({
  name: 'AsfAccordion',
  emits: ['accordion:change'],
  props: {
    allowMultiple: { type: Boolean as PropType<AsfAccordionProps['allowMultiple']>, default: true },
    initialOpen: { type: [String, Array] as PropType<AsfAccordionProps['initialOpen']>, default: () => '' },
    large: { type: Boolean as PropType<AsfAccordionProps['large']>, default: false }
  },
  setup(props: AsfAccordionProps, { emit, slots }) {
    const initialOpenRef = toRef(props, 'initialOpen')
    const internalMultiple = ref(props.allowMultiple)
    const accordion: Ref<HTMLElement | null> = ref(null)
    const accessibleItems: Ref<FocusableElement[]> = ref([])
    const selectedItems = ref<Record<string, string>>({})
    const { currentElement, setFocusToPreviousItem, setFocusToNextItem, setFocusToFirstItem, setFocusToLastItem } =
      useAccessibility(accessibleItems)

    const setFocusToElement = (el: FocusableElement) => {
      if (!el) return

      nextTick(() => {
        currentElement.value = el
        el.focus()
      })
    }

    const getSelectedItems = () => {
      if (!props.initialOpen) return
      const itemsObject = {} as Record<string, string>
      if (props.initialOpen === 'all' && slots.default) {
        const children = slots.default()
        internalMultiple.value = true
        children?.forEach((child) => {
          if (child?.props?.id) {
            itemsObject[child.props.id] = child.props.id
          }
        })
      }

      if (Array.isArray(props.initialOpen)) {
        props.initialOpen.forEach((element) => {
          itemsObject[element] = element
        })
      }

      selectedItems.value = itemsObject
    }

    watch(initialOpenRef, () => {
      getSelectedItems()
    })

    const handleAccordionItemToggle = (id: string) => {
      if (selectedItems.value[id]) {
        selectedItems.value[id] = ''
        return emit('accordion:change', Object.keys(selectedItems.value))
      }

      if (!internalMultiple.value) {
        selectedItems.value = {}
      }

      selectedItems.value[id] = id
      return emit('accordion:change', Object.keys(selectedItems.value))
    }

    const handleKeydown = (event: KeyboardEvent) => {
      let preventActions = false
      switch (event.key) {
        case AsfKeyValue.PAGEUP:
        case AsfKeyValue.HOME:
          preventActions = true
          setFocusToFirstItem(setFocusToElement)
          break

        case AsfKeyValue.PAGEDOWN:
        case AsfKeyValue.END:
          preventActions = true
          setFocusToLastItem(setFocusToElement)
          break

        case AsfKeyValue.DOWN:
          preventActions = true
          setFocusToNextItem(setFocusToElement)
          break
        case AsfKeyValue.UP:
          preventActions = true
          setFocusToPreviousItem(setFocusToElement)
          break

        default:
          break
      }

      if (preventActions) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    onMounted(() => {
      nextTick(() => {
        if (!accordion.value) {
          return
        }

        accessibleItems.value = Array.from(accordion.value.querySelectorAll('.asf-accordion-toggle'))
        getSelectedItems()
      })
    })

    provide(SelectedItemsKey, selectedItems)
    provide(ToggleItemKey, handleAccordionItemToggle)
    provide(HandleKeydownKey, handleKeydown)
    return { accordion }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Accordion/Accordion.css';
</style>
