import { computed, ref } from 'vue'
import { UseContent } from 'shared/types'
import { ContentClient, DefaultContentBody } from 'dc-delivery-sdk-js'
import { AmplienceContent } from '../types'

const logger = Logger.getLogger({ scope: 'amplience', method: 'useContent' })

type ContentMap = Record<string, AmplienceContent[]>

export const useContent: UseContent<AmplienceContent, Error | undefined> = (id) => {
  const client = new ContentClient({
    hubName: 'astoundctsandbox',
    locale: 'en'
  })

  const content = ref<ContentMap>({})
  const loading = ref<boolean>(false)
  const error = ref()

  async function addContentHierarchyToContent<T extends AmplienceContent>(contentHierarchy: T) {
    if (!contentHierarchy.navigationBlockId) {
      return contentHierarchy
    }

    const { body: hierarchyContent } = await client.getContentItemByKey<T & DefaultContentBody>(
      contentHierarchy.navigationBlockId
    )

    const contents: T[] = []

    async function loadHierarchy(cursor?: string) {
      const res = await client
        .filterByContentType<T>('https://agsf.com/cms/schema/v1/content/blocks/navigation-item.json')
        .filterBy('/hierarchyGroupId', hierarchyContent.hierarchyGroupId)
        .page(12, cursor)
        .request({
          format: 'inlined',
          depth: 'all'
        })

      contents.push(...res.responses.map((value) => value.content))

      if (res.page.nextCursor) {
        await loadHierarchy(res.page.nextCursor)
      }
    }

    await loadHierarchy()

    hierarchyContent.children = contents

    if (process.server) {
      hierarchyContent._meta = hierarchyContent._meta.toJSON()
    }
    contentHierarchy.hierarchy = hierarchyContent

    return contentHierarchy
  }

  async function getContentMapByKeys<T extends AmplienceContent>(keys: string[]) {
    const { responses } = await client.getContentItemsByKey<T>(keys)
    const contents = await Promise.all(
      responses
        .flatMap((value) => {
          return 'content' in value ? value.content : []
        })
        .map(addContentHierarchyToContent)
    )

    return contents.reduce(
      (acc, item) => {
        return { ...acc, [item._meta?.deliveryKey as string]: [item] }
      },
      {} as { [key: string]: T[] }
    )
  }

  return {
    async searchContent({ keys }) {
      logger.debug(`useContent/${id}/search`, keys)
      loading.value = true

      const { error: responseErr, data } = await useAsyncData([id, keys].join(','), async () => {
        return getContentMapByKeys<AmplienceContent>(keys)
      })

      if (data.value) {
        content.value = data.value
      }

      if (responseErr.value) {
        error.value = responseErr.value
        logger.error(`${id}/search`, responseErr.value)
      }

      loading.value = false
    },
    content: computed(() => content.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  }
}
