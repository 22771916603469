<template>
  <div v-html="$sanitize(content)" />
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ContentHTMLProps } from '../types'

export default defineComponent({
  name: 'ContentHTML',
  props: {
    content: { type: String as PropType<ContentHTMLProps['content']>, required: true }
  }
})
</script>
