// Use this file to define constants or utils data for the component
import { InjectionKey, Ref } from 'vue'

export type TSelectedItemsKey = Ref<{ [key: string]: string }>
export type TToggleItemKey = (id: string) => void
export type THandleKeydownKey = (event: KeyboardEvent) => void

export const SelectedItemsKey: InjectionKey<TSelectedItemsKey> = Symbol('SelectedItems')
export const ToggleItemKey: InjectionKey<TToggleItemKey> = Symbol('ToggleItemKey')
export const HandleKeydownKey: InjectionKey<THandleKeydownKey> = Symbol('HandleKeydownKey')
