<template>
  <span class="inline-block" :style="styles">{{ text }}</span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ContentTextFieldProps } from '../types'

export default defineComponent({
  name: 'ContentTextField',
  props: {
    text: { type: String as PropType<ContentTextFieldProps['text']>, default: '' },
    color: { type: String as PropType<ContentTextFieldProps['color']>, default: '' },
    alignment: { type: String as PropType<ContentTextFieldProps['alignment']>, default: '' }
  },
  setup(props: ContentTextFieldProps) {
    const styles = computed(() => ({
      // an undefined value must be defined explicitly to avoid the case when the value is an empty string.
      // this is possible due to the way how Amplience is configured
      color: props.color || undefined,
      textAlign: props.alignment || undefined
    }))

    return { styles }
  }
})
</script>
