import { AsfVideoProps, AsfVideoProvider } from '@ui/types'

export const getVideoProvider = (url: string): AsfVideoProvider | undefined => {
  if (url.includes('youtube') || url.includes('youtu.be')) {
    return 'youtube'
  }

  if (url.includes('vimeo')) {
    return 'vimeo'
  }

  if (url.includes('dailymotion') || url.includes('dai.ly')) {
    return 'dailymotion'
  }

  return undefined
}

export const getDailyMotionID = (url: string) => {
  return new URL(url).pathname.split('/').pop() as string
}

export const getVimeoID = (url: string): string => {
  return new URL(url).pathname.split('/').pop() as string
}

export const getYouTubeID = (url: string): string => {
  const splitUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/u)

  if (splitUrl[2] !== undefined) {
    return splitUrl[2].split(/[^0-9a-z_-]/iu)[0] as string
  }

  return splitUrl[0] || ''
}

export const getDailyMotionThumbnail = (
  id: string,
  quality: AsfVideoProps['thumbnailQuality'] = 'high'
): string | undefined => {
  if (id) {
    let qualityKey = ''
    if (quality === 'default') {
      qualityKey = 'x240'
    } else if (quality === 'medium') {
      qualityKey = 'x360'
    } else if (quality === 'high') {
      qualityKey = 'x480'
    } else if (quality === 'standard') {
      qualityKey = 'x720'
    } else if (quality === 'maxres') {
      qualityKey = 'x1080'
    }
    return `https://s1.dmcdn.net/v/${id}/${qualityKey}`
  }

  return undefined
}

export const getVimeoThumbnail = (
  id: string,
  quality: AsfVideoProps['thumbnailQuality'] = 'high'
): string | undefined => {
  if (id) {
    let qualityKey = ''
    if (quality === 'default') {
      qualityKey = '200x150'
    } else if (quality === 'medium') {
      qualityKey = '295x166'
    } else if (quality === 'high') {
      qualityKey = '640x360'
    } else if (quality === 'standard') {
      qualityKey = '960x540'
    } else if (quality === 'maxres') {
      qualityKey = '1280x720'
    }
    return `https://i.vimeocdn.com/video/${id}_${qualityKey}.jpg`
  }

  return undefined
}

export const getYoutubeThumbnail = (
  id: string,
  quality: AsfVideoProps['thumbnailQuality'] = 'high'
): string | undefined => {
  if (id) {
    let qualityKey = ''
    if (quality === 'default') {
      qualityKey = 'default'
    } else if (quality === 'medium') {
      qualityKey = 'mqdefault'
    } else if (quality === 'high') {
      qualityKey = 'hqdefault'
    } else if (quality === 'standard') {
      qualityKey = 'sddefault'
    } else if (quality === 'maxres') {
      qualityKey = 'maxresdefault'
    }

    return `https://img.youtube.com/vi/${id}/${qualityKey}.jpg`
  }

  return undefined
}

export const fetchingVideo = async (
  src: string,
  type: AsfVideoProvider = 'youtube'
): Promise<Record<string, unknown>> => {
  let url = ''

  if (type === 'youtube') {
    url = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${getYouTubeID(src)}&format=json`
  }

  if (type === 'vimeo') {
    url = `https://vimeo.com/api/oembed.json?url=${src}`
  }

  if (type === 'dailymotion') {
    url = `https://api.dailymotion.com/video/${getDailyMotionID(src)}?fields=id,title,thumbnail_url`
  }

  const response = await fetch(url)
  return response.json()
}
