<template>
  <AsfLink
    v-e2e="'ta-content-link'"
    :link="url"
    :target="target"
    :prevent="Boolean($attrs.prevent)"
    :is-anchor="isAnchor"
    :class="{ 'underline underline-offset-auto': isUnderlined }"
    v-bind="$attrs"
    class="asf-content-link"
  >
    <slot />
    <span :class="{ 'sr-only': isHideLabel }">{{ label }}</span>
  </AsfLink>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ContentLinkProps } from '../types'

export default defineComponent({
  name: 'ContentLink',
  props: {
    url: { type: String as PropType<ContentLinkProps['url']>, default: '' },
    label: { type: String as PropType<ContentLinkProps['label']>, default: '' },
    target: { type: String as PropType<ContentLinkProps['target']>, default: '_self' },
    isUnderlined: { type: Boolean as PropType<ContentLinkProps['isUnderlined']>, default: false },
    isHideLabel: { type: Boolean as PropType<ContentLinkProps['isHideLabel']>, default: false }
  },
  setup(props: ContentLinkProps, { attrs }) {
    const isAnchor = computed(() => props.url[0] === '#' || Boolean(attrs['is-anchor'] || attrs.isAnchor))

    return { isAnchor }
  }
})
</script>
