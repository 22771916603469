<template>
  <section
    v-e2e="'accordion-item'"
    class="asf-accordion-item"
    :class="{ 'is-open': selected }"
    role="region"
    :aria-labelledby="`heading-${id}`"
  >
    <AsfHeading tag="h2" appearance="h5" :id="`heading-${id}`">
      <AsfButton
        v-e2e="'accordion-toggle'"
        class="asf-accordion-toggle"
        :aria-controls="`refinement-${id}`"
        :aria-expanded="selected.toString()"
        @click="toggleSection()"
        @keydown="handleKeydownEvent"
      >
        {{ label }}
        <AsfChevron :appearance="chevronAppearance" />
      </AsfButton>
    </AsfHeading>
    <Transition name="asf-expand" @after-enter="onAfterEnter" @enter="onEnter" @leave="onLeave">
      <div :id="`refinement-${id}`" class="asf-accordion-content" v-show="selected" :aria-hidden="!selected">
        <div class="asf-accordion-content__inner">
          <slot />
        </div>
      </div>
    </Transition>
  </section>
</template>
<script lang="ts">
import { computed, defineComponent, inject, PropType, TransitionProps } from 'vue'
import { AsfAccordionItemProps, AsfChevronProps } from '@ui/types'
import {
  HandleKeydownKey,
  SelectedItemsKey,
  THandleKeydownKey,
  ToggleItemKey,
  TSelectedItemsKey,
  TToggleItemKey
} from '../Accordion.utils'

export default defineComponent({
  name: 'AsfAccordionItem',
  props: {
    id: { type: String as PropType<AsfAccordionItemProps['id']>, required: true },
    label: { type: String as PropType<AsfAccordionItemProps['label']>, required: true }
  },
  setup(props: AsfAccordionItemProps) {
    let selectedItems: TSelectedItemsKey | undefined
    let toggleItem: TToggleItemKey | undefined
    let handleKeydown: THandleKeydownKey | undefined

    if (process.client) {
      selectedItems = inject(SelectedItemsKey)
      toggleItem = inject(ToggleItemKey)
      handleKeydown = inject(HandleKeydownKey)
    }

    const onAfterEnter: TransitionProps['onAfterEnter'] = (el) => {
      // @ts-ignore
      el.style.height = 'auto'
    }
    const onEnter: TransitionProps['onEnter'] = (el) => {
      // @ts-ignore
      el.style.height = 'auto'
      const height = getComputedStyle(el).height
      // @ts-ignore
      el.style.height = '0'
      requestAnimationFrame(() => {
        // @ts-ignore
        el.style.height = height
      })
    }
    const onLeave: TransitionProps['onLeave'] = (el) => {
      // @ts-ignore
      el.style.height = getComputedStyle(el).height
      requestAnimationFrame(() => {
        // @ts-ignore
        el.style.height = '0'
      })
    }

    const selected = computed(() => Boolean(selectedItems?.value[props.id]))
    const chevronAppearance = computed<AsfChevronProps['appearance']>(() => (selected.value ? 'top' : 'bottom'))
    const toggleSection = () => toggleItem && toggleItem(props.id)
    const handleKeydownEvent = (event: KeyboardEvent) => handleKeydown && handleKeydown(event)

    return { selected, chevronAppearance, toggleSection, handleKeydownEvent, onAfterEnter, onEnter, onLeave }
  }
})
</script>
