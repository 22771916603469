<template>
  <div class="asf-banner__caption-inner">
    <p v-if="introTitle" class="asf-banner__intro-title">
      <slot name="intro-title" :content="introTitle">
        {{ introTitle }}
      </slot>
    </p>
    <slot v-if="title" name="title" :content="title" :class-name="['asf-banner__title']">
      {{ title }}
    </slot>
    <slot v-if="subtitle" name="subtitle" :content="subtitle" :class-name="['asf-banner__subtitle']">
      {{ subtitle }}
    </slot>
    <p v-if="description" class="asf-banner__description">
      <slot name="description" :content="description">
        {{ description }}
      </slot>
    </p>
    <div v-if="links && links.length" class="asf-banner__actions">
      <AsfLink
        v-for="(link, idx) in links"
        :key="idx"
        :aria-label="`${link.label} ${title}`"
        :link="link.link"
        :target="link.target"
        :class="link.className"
      >
        {{ link.label }}
      </AsfLink>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { AsfBannerProps } from '@ui/types'

export default defineComponent({
  name: 'AsfBannerCaption',
  props: {
    links: { type: Array as PropType<AsfBannerProps['links']>, default: () => [] },
    title: { type: String as PropType<AsfBannerProps['title']>, default: '' },
    subtitle: { type: String as PropType<AsfBannerProps['subtitle']>, default: '' },
    introTitle: { type: String as PropType<AsfBannerProps['introTitle']>, default: '' },
    description: { type: String as PropType<AsfBannerProps['description']>, default: '' }
  }
})
</script>
